<template>
  <div class='d-none d-md-block'>
    <v-app-bar app flat>
      <v-app-bar-nav-icon @click='toggleSidebar'></v-app-bar-nav-icon>
      <v-row align='center' justify='end'>
        <div class='text-end mr-8'>
          <h4 class='text-start'>{{ getUserInfo }}</h4>
          <span class='company-name'>{{ getCompany }}</span>
        </div>
        <v-avatar class='avatar mr-4' color='primary' size='48'>
          <v-img :src='image'></v-img>
        </v-avatar>
        <v-divider class='divider mx-4' vertical></v-divider>
        <v-btn class='mr-2' icon @click='logout'>
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </v-row>
    </v-app-bar>
  </div>
</template>

<script>
import home from '../services/home'

export default {
  name: 'HeaderComponent',
  computed: {
    user() {
      return this.$store.getters.user
    },
    getUserInfo() {
      if (this.$store.getters.user.id_rol === 4 || this.$store.getters.user.id_rol === 5) {
        return this.$store.state.employeeStore.employeeProfile.fullName
      }
      return this.fullName
    },
    getCompany() {
      if (this.user.id_rol === 2) {
        return this.user.member_name
      }
      return ''
    },
    image() {
      return this.$store.getters.image
    },
    impersonal() {
      return this.$store.getters.impersonal
    },
    userCopy() {
      return this.$store.getters.userCopy
    },
    fullName() {
      return this.user.lastName ? this.user.name + ' ' + this.user.lastName : this.user.name
    }
  },
  data: () => ({
    showSidebar: true
  }),
  methods: {
    toggleSidebar() {
      this.showSidebar = !this.showSidebar
      this.$emit('toggleSidebar', this.showSidebar)
    },
    async logout() {
      try {
        await home.logout()
        setTimeout(() => {
          this.$store.dispatch('impersonal', false)
          this.$store.dispatch('electionStore/clearElection')
          this.$store.dispatch('userCopy', {
            user: {
              name: '',
              rol: '',
              image: ''
            },
            token: ''
          })
          this.$store.dispatch('token', '')
          this.$store.dispatch('user', {
            name: '',
            rol: '',
            image: ''
          })
          this.$store.state.employeeStore.isShowedVoterRegistrationPopup = false
          this.$store.state.employeeStore.showedVoterRegistrationPopupAt = null
          this.$router.replace({ name: 'Login' })
        }, 0)
      } catch (err) {
        // empty
      }
    }
  }
}
</script>

<style scoped>
.company-name {
  font-size: 14px;
  color: #667085;
}
</style>
