import { HTTP } from '../../../http-common'
import helper from '../../services'
import store from '@/store'

const employeeStore = {
  namespaced: true,
  state: {
    upcomingElections: [],
    faqs: [],
    disabledViewCheckList: true,
    indexTask: null,
    modalWindow: {
      dialog: false,
      title: '',
      id: null,
      data: {}
    },
    detailCard: {},
    selectedElection: {},
    selectedElectionId: null,
    employeeId: null,
    elections: [],
    employeeProfile: {},
    customAlertData: {
      show: false,
      color: 'grey',
      message: '',
      timeout: 4000
    },
    employeeModules: [],
    opportunitiesByEmployee: {},
    volunteeringRequests: [],
    previousVolunteering: [],
    votingRequests: [],
    votingRequestsStatus: false,
    donationHistory: [],
    activeSubscriptions: [],
    upcomingVolunteering: [],
    eventBus: false,
    communicationDialogData: {
      show: false,
      title: '',
      data: {}
    },
    communications: [],
    employeesToReport: [],
    employeeRequests: {},
    votingStatistic: [],
    registrationURL: '',
    isShowedVoterRegistrationPopup: false,
    showedVoterRegistrationPopupAt: null,
    isFirstLogin: null
  },
  getters: {
    isModuleActive: ({ employeeModules }) => module => employeeModules.includes(module),
    getDaysOff: ({ employeeProfile }) => employeeProfile.daysOff,
    getFilteredSubscriptionsById: ({ activeSubscriptions }) => subsToCancelId => activeSubscriptions.filter(el => el.id !== subsToCancelId),
    getModalWindow: ({ modalWindow }) => modalWindow,
    getFilteredEmployeesToReport: ({
                                     employeesToReport,
                                     employeeId
                                   }) => employeesToReport.filter(employee => employee.id !== employeeId),
    getEmployeeRequests: ({ employeeRequests }) => {
      let requests = []
      for (const req in employeeRequests) {
        requests = [...requests, ...employeeRequests[req]]
      }
      return requests
        .map((el, i) => ({ index: i, ...el }))
        .sort((a, b) => new Date(b.date) - new Date(a.date))
    },
    getPercentageOfLoggedEmployees: () => allEmployees => {
      const loggedEmployees = allEmployees.filter(el => el.lastLoginDate)
      return Math.round(loggedEmployees.length / allEmployees.length * 100) || '0'
    }
  },
  mutations: {
    SET_EMPLOYEE_ID: (state, id) => state.employeeId = id,
    SET_EMPLOYEE_PROFILE: (state, employee) => state.employeeProfile = employee,
    SET_CUSTOM_ALERT_DATA: (state, data) => state.customAlertData = data,
    SET_UPCOMING_ELECTIONS: (state, data) => state.upcomingElections = data,
    SET_EMPLOYEE_MODULES: (state, modules) => state.employeeModules = modules,
    SET_OPPORTUNITIES_BY_EMPLOYEE: (state, data) => state.opportunitiesByEmployee = data,
    SET_VOLUNTEERING_REQUESTS: (state, data) => state.volunteeringRequests = data,
    SET_PREVIOUS_VOLUNTEERING: (state, data) => state.previousVolunteering = data,
    SET_VOTING_REQUESTS: (state, data) => state.votingRequests = data,
    SET_DONATION_HISTORY: (state, data) => state.donationHistory = data,
    SET_ACTIVE_SUBSCRIPTIONS: (state) => {
      state.activeSubscriptions = state.activeSubscriptions.map(el => {
        el.totalAmount = el.amount / 100
        el.commission = (state.commission * el.totalAmount) / 100
      })
    },
    SET_UPCOMING_VOLUNTEERING: (state, data) => state.upcomingVolunteering = data,
    SET_DETAIL_CARD: (state, data) => state.detailCard = data,
    SET_VIEW_CHECK_LIST: (state, data) => state.disabledViewCheckList = data,
    SET_MODAL_WINDOW: (state, data) => state.modalWindow = data,
    SET_TASKS: (state, data) => state.modalWindow.data = data,
    SET_COMPLETED_TASK: (state, data) => console.log(data, state),
    SET_EVENT_BUS: (state, value) => {
      state.eventBus = value
      setTimeout(() => state.eventBus = false, 1000)
    },
    SET_VOTING_REQUESTS_STATUS: (state, data) => state.votingRequestsStatus = data,
    SET_COMMUNICATION_DIALOG_DATA: (state, data) => state.communicationDialogData = data,
    SET_COMMUNICATIONS: (state, data) => state.communications = data,
    SET_EMPLOYEES_TO_REPORT: (state, data) => state.employeesToReport = data,
    SET_EMPLOYEE_REQUESTS: (state, data) => state.employeeRequests = data,
    SET_SCHEDULE_VUID: (state, value) => state.employeeProfile.scheduleVuid = value,
    SET_VOTING_STATISTIC: (state, data) => state.votingStatistic = data,
    SET_FAQS: (state, data) => state.faqs = data,
    SET_REGISTRATION_URL: (state, url) => state.registrationURL = url
  },
  actions: {
    async getAllEmployees(context, params) {
      try {
        return await HTTP.get('business/employees', {
          params,
          headers: helper.setHeaders()
        })
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getSuspendedEmployees(context, params) {
      try {
        return await HTTP.get('business/employees/suspended', {
          params,
          headers: helper.setHeaders()
        })
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getMemberSuspendedEmployees(context, params) {
      try {
        return await HTTP.get('member/employees/suspended', {
          params,
          headers: helper.setHeaders()
        })
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async createEmployee(context, data) {
      try {
        await HTTP.post('employees', data, {
          headers: helper.setHeaders()
        })
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async editEmployeeByAdmin(context, data) {
      try {
        const res = await HTTP.put(`employees/${ data.id }`, data, {
          headers: helper.setHeaders()
        })
        await helper.errorHandler(res)
        return res.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getEmployeeInfoToEdit(context, employeeId) {
      try {
        const { data: { employees } } = await HTTP.get(`employees/${ employeeId }`, {
          headers: helper.setHeaders()
        })
        return employees
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async editEmployeeByAdminWithoutRescreen(context, data) {
      try {
        const res = await HTTP.post(
          'employees/update-without-re-screen/' + data.id,
          data,
          {
            headers: helper.setHeaders()
          }
        )
        await helper.errorHandler(res)
        return res.data.success
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async deleteEmployees(context, employees) {
      try {
        await HTTP.put('employees/delete-multiple', employees, {
          headers: helper.setHeaders()
        })
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getEmployeesRoles() {
      try {
        const res = await HTTP.get('employees-roles', {
          headers: helper.setHeaders()
        })
        return res.data.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async resolveRescreenAddress(ctx, data) {
      try {
        const res = await HTTP.post(
          'business/resolve-rescreen-address-exception',
          data,
          { headers: helper.setHeaders() }
        )
        await helper.errorHandler(res)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async rescreenAddress(ctx, ids) {
      try {
        const res = await HTTP.post(
          'business/rescreen-address',
          ids,
          { headers: helper.setHeaders() }
        )
        await helper.errorHandler(res)
        return res.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async postRegistrationStatus(ctx, data) {
      try {
        const res = await HTTP.post(`business/set-registration_stage/${ data.employee_id }`, data.stageId, {
          headers: helper.setHeaders()
        })
        await helper.errorHandler(res)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getRegistrationStage() {
      try {
        const res = await HTTP.get('business/get-registration-stage', {
          headers: helper.setHeaders()
        })
        return res.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getRegistrationEmployees(context, params) {
      try {
        const res = await HTTP.get(
          'business/get-employees-registration',
          {
            params,
            headers: helper.setHeaders()
          }
        )
        return res.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getEligibilityDetails() {
      try {
        const res = await HTTP.get('get-eligibility-details', { headers: helper.setHeaders() })
        return res.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async reScreenEmployees(context, employeesIds) {
      try {
        const res = await HTTP.post(
          'employees/rescreen', employeesIds,
          { headers: helper.setHeaders() }
        )
        await helper.errorHandler(res)
        return res.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async resolveRescreenException(context, data) {
      try {
        await HTTP.put(
          'resolve-rescreen-exception', data,
          { headers: helper.setHeaders() }
        )
        await store.dispatch('snackbar', {
          show: true,
          color: 'success',
          message: 'Employee updated successfully!'
        })
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async searchEmployee(context, data) {
      try {
        const res = await HTTP.get(
          `/search-for-create-employee`,
          {
            headers: helper.setHeaders(),
            params: data
          }
        )
        return res.data.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getFAQContent({ commit }) {
      const response = await HTTP
        .get(`/faq`, { headers: helper.setHeaders() })
        .catch(async (e) => await helper.errorHandler(e.response))
      commit('SET_FAQS', response.data.data)
    },
    async deleteRequest(context, { id, requestId, requestType }) {
      try {
        await HTTP.delete(
          `/employees/${ id }/volunteering-requests/${ requestType }/${ requestId }`,
          { headers: helper.setHeaders() }
        )
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getRequestHistory({ commit }, id) {
      try {
        commit('SET_EMPLOYEE_REQUESTS', [])
        const response = await HTTP.get(
          `/employees/${ id }/request-history`,
          { headers: helper.setHeaders() }
        )
        commit('SET_EMPLOYEE_REQUESTS', response.data.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async submitVotingRequests(context, data) {
      try {
        await HTTP.post(
          'vote-requests', data,
          { headers: helper.setHeaders() }
        )
        await store.dispatch('snackbar', {
          show: true,
          color: 'green',
          message: 'Voting day scheduled successfully!'
        })
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async deleteVotingRequests(context, data) {
      try {
        await HTTP.delete(
          `vote-requests/${ data }`,
          { headers: helper.setHeaders() }
        )
        await store.dispatch('snackbar', {
          show: true,
          color: 'green',
          message: 'Voting day delete successfully!'
        })
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getVotingStatistic({ rootState, state, commit }) {
      try {
        const response = await HTTP.get(
          `employees/${ state.employeeId }/voting-statistics/${ rootState.electionStore.selectedElection.id }/election-data`,
          { headers: helper.setHeaders() }
        )
        commit('SET_VOTING_STATISTIC', response.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async completedTask(context, { completed, id }) {
      try {
        await HTTP.post(
          `task-completed-note/${ id }`,
          { completed: completed },
          { headers: helper.setHeaders() }
        )
        // commit('SET_COMPLETED_TASK', response.data)
      } catch (e) {
        await helper.errorHandler(e.response)
        return e.response
      }
    },
    async getIndexTask({ commit }, id) {
      try {
        const response = await HTTP.get(
          `task-election/${ id }`,
          { headers: helper.setHeaders() })
        const tasks = []
        response.data.data.forEach(el => tasks.push(...el.data))
        commit('SET_TASKS', tasks)
      } catch (e) {
        await helper.errorHandler(e.response)
        return e.response
      }
    },
    async getElections({ commit }, id) {
      try {
        commit('SET_UPCOMING_ELECTIONS', [])
        const response = await HTTP.get(
          '/elections/employees/' + id + '/vote-requests',
          { headers: helper.setHeaders() }
        )
        commit('SET_UPCOMING_ELECTIONS', response.data.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getDetailCard({ commit }, id) {
      try {
        const response = await HTTP.get(
          `task-detail/${ id }`,
          { headers: helper.setHeaders() }
        )
        if (response.data.data.completed !== '-' && response.data.data.limit !== '-') {
          commit('SET_VIEW_CHECK_LIST', false)
        }
        commit('SET_DETAIL_CARD', response.data.data)
      } catch (e) {
        await helper.errorHandler(e.response)
        return e.response
      }
    },
    async getEmployeeId({ commit, rootGetters }) {
      try {
        const response = await HTTP.get(
          `employees/users/${ rootGetters.user.id }/employee`,
          { headers: helper.setHeaders() }
        )
        commit('SET_EMPLOYEE_ID', response.data.employeeId)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async addRegistrationRequest({ state }, date, stateProp) {
      try {
        await HTTP.post(
          `employees/${ state.employeeId }/registration-request`,
          { date, stateProp },
          { headers: helper.setHeaders() }
        )
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getModules({ commit, rootGetters }) {
      try {
        const response = await HTTP.get(
          `members/${ rootGetters.user.profile_id.member_id }/modules`,
          { headers: helper.setHeaders() }
        )
        commit('SET_EMPLOYEE_MODULES', response.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getEmployeeProfile({ state, commit }) {
      try {
        const response = await HTTP.get(
          `employees/${ state.employeeId }/profile`,
          { headers: helper.setHeaders() }
        )
        commit('SET_EMPLOYEE_PROFILE', response.data.employee)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getOpportunitiesByEmployee({ state, commit }, filters) {
      try {
        const response = await HTTP.get(
          `employees/${ state.employeeId }/openOpportunities`,
          {
            params: filters,
            headers: helper.setHeaders()
          })
        commit('SET_OPPORTUNITIES_BY_EMPLOYEE', response.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getVolunteeringRequests({ state, commit }) {
      try {
        const response = await HTTP.get(
          `employees/${ state.employeeId }/volunteering-requests`,
          { headers: helper.setHeaders() }
        )
        commit('SET_VOLUNTEERING_REQUESTS', response.data.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getPreviousVolunteering({ state, commit }) {
      try {
        const response = await HTTP.get(
          `employees/${ state.employeeId }/previous-requests`,
          { headers: helper.setHeaders() }
        )
        commit('SET_PREVIOUS_VOLUNTEERING', response.data.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getVotingRequests({ state, commit }) {
      try {
        const response = await HTTP.get(
          `employees/${ state.employeeId }/voting-requests`,
          { headers: helper.setHeaders() }
        )
        commit('SET_VOTING_REQUESTS', response.data.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getDonationHistory({ state, commit }) {
      try {
        const response = await HTTP.get(
          `employees/${ state.employeeId }/get/donations`,
          { headers: helper.setHeaders() }
        )
        commit('SET_DONATION_HISTORY', response.data.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getActiveSubscriptions({ state, commit }) {
      try {
        const response = await HTTP.get(
          `employees/${ state.employeeId }/get/subscriptions`,
          { headers: helper.setHeaders() }
        )
        commit('SET_ACTIVE_SUBSCRIPTIONS', response.data.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },

    async getUpcomingVolunteering({ state, commit }) {
      try {
        const response = await HTTP.get(
          `employees/${ state.employeeId }/charity-engagement`,
          { headers: helper.setHeaders() }
        )
        commit('SET_UPCOMING_VOLUNTEERING', response.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },

    async submitVolunteerRequest({ state }, eventDayId) {
      try {
        await HTTP.post(
          `employees/${ state.employeeId }/store-volunteer`,
          { eventDayId },
          { headers: helper.setHeaders() }
        )
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },

    async cancelVolunteerRequest({ state }, eventDayId) {
      try {
        await HTTP.post(
          `employees/${ state.employeeId }/delete-volunteer`,
          { eventDayId },
          { headers: helper.setHeaders() }
        )
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },

    async sendRegistrationRequest(context, { employeeId, date, state }) {
      try {
        await HTTP.post(
          `employees/${ employeeId }/registration-request`,
          { date, state },
          { headers: helper.setHeaders() }
        )
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getCommunications({ commit }, employeeId) {
      try {
        const response = await HTTP.get(
          `employees/${ employeeId }/scheduled-communication-plan`,
          { headers: helper.setHeaders() }
        )
        commit('SET_COMMUNICATIONS', response.data.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getEmployeesToReport({ commit }, memberId) {
      try {
        const response = await HTTP.get(
          `members/${ memberId }/employees-to-report`,
          { headers: helper.setHeaders() }
        )
        commit('SET_EMPLOYEES_TO_REPORT', response.data.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async enableDisableEmployee(context, id) {
      try {
        await HTTP.put(
          'employees/enable-disable/', id,
          { headers: helper.setHeaders() }
        )
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async enableDisableMultipleEmployees(context, employees) {
      try {
        await HTTP.put(
          'employees/enable-disable-multiple', employees,
          { headers: helper.setHeaders() }
        )
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async validateAddress(context, data) {
      try {
        const res = await HTTP.get(
          '/google-maps-place',
          {
            headers: helper.setHeaders(),
            params: data
          })
        return res.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async editEmployee(context, data) {
      try {
        await HTTP.put(
          `employees/${ data.id }/update-profile`, data,
          { headers: helper.setHeaders() }
        )
      } catch (e) {
        await helper.errorHandler(e.response)
      }
      if (data.profilePictureObject) {
        let formData = new FormData()
        let headers = helper.setHeaders()
        headers['Content-Type'] = 'multipart/form-data'
        formData.append('profilePictureObject', data.profilePictureObject)
        try {
          await HTTP.post(
            `employees/${ data.id }/upload-profile-picture`, formData,
            { headers: headers }
          )
        } catch (e) {
          await helper.errorHandler(e.response)
        }
      }
    },
    async sendRegistrationEmployee(context, data) {
      try {
        return await HTTP.post(
          'employee-create',
          data,
          {
            headers: helper.setHeaders()
          }
        )
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getRegistrationURL({ commit }) {
      try {
        const res = await HTTP.get(
          'get-registration-url',
          {
            headers: helper.setHeaders()
          }
        )
        commit('SET_REGISTRATION_URL', res.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },

    async rescreenOnRegistration() {
      try {
        const res = await HTTP.post(
          'employees/rescreen-on-registration', {},
          {
            headers: helper.setHeaders()
          }
        )
        return res.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async applyRescreenOnRegistration(context, data) {
      try {
        const res = await HTTP.post(
          'employees/apply-rescreen-on-registration', data,
          {
            headers: helper.setHeaders()
          }
        )
        await helper.errorHandler(res)
        return res.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },

    async applyVoterAddress() {
      try {
        const res = await HTTP.post(
          'employees/apply-voter-address', {},
          {
            headers: helper.setHeaders()
          }
        )
        // await helper.errorHandler(res)
        return res.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async changeERSTabStatus() {
      try {
        const res = await HTTP.post(
          'employees/change-registration-stage', {},
          {
            headers: helper.setHeaders()
          }
        )
        await helper.errorHandler(res)
        return res.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async overwriteVoterRegistrationAddress(context, data) {
      try {
        const res = await HTTP.post(
          'employees/overwrite-voter-address', data,
          {
            headers: helper.setHeaders()
          }
        )
        await helper.errorHandler(res)
        return res.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },    
    async sendWelcomeEmail(context, data) {
      try {
        const res = await HTTP.post(
          'employees/send-welcome-emails', data,
          {
            headers: helper.setHeaders()
          }
        )
        await helper.errorHandler(res)
        return res.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },

  }
}

export default employeeStore
